@media (min-width: 1200px){
  .container {
      max-width: 1044px;
  }
}
/* .movie-wrapper{
  transition: transform .3s;
width: 100%;
height: 210px;
margin: 0 auto;
} */
/* .movie-wrapper:hover{
  -ms-transform: scale(1.3);  / * IE 9 
-webkit-transform: scale(1.3); / * Safari 3-8 
transform: scale(1.3); 
} */
/* .movie-wrapper .movie-img{
 
  width: 100%;
  height: 210px;
  object-fit: cover;
}
.movie-wrapper-container-expo{
  justify-content: space-around;
  padding: 40px 35px!important;
}
.movie-wrapper-container{
  margin: 1% 1%;
  width: 21%;
}
/* .movie-wrapper-content {
  display: none;
} */
    

.container-one {
display: flex;
margin-top: 50px;
padding: 0 3%;
}

.item-one {
position: relative;
display: block;
flex: 1 1 0px;
transition: transform 500ms;
}

.container-one .item-one:hover {
transform: scale(1.3);
z-index: 1;
}



.item-one img {
display: block;
max-width: 100%;
}
 .movie-wrapper-content {
  display: block;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  padding: 8% 4%;
  background: #33333385;
  overflow: hidden;
} 
.item-one:hover +.movie-wrapper-content {
  display: block;
  background: #33333387;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  padding: 10% 4%;
 
}