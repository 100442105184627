.movie-container {
    margin-top: 78px;
}

.movie-img-primary {
    width: 100%;
    height: 100vh;
    object-fit: fill;
}
.movie-extra-img{
    height:300px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}
.movie-primary-detail-container {
    text-align: left;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 35%;
    height: 100vh;
    background: linear-gradient(88deg, black, transparent);
}

.movie-primary-detail-items {
    position: absolute;
    top:4%;
    left: 10%;
}

.movie-primary-detail-items h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
}

.movie-primary-detail-items .movie-meta {
    color: #a3a3a3;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.movie-primary-detail-items .movie-description {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 100%;
}
.movie-primary-detail-items .movie-logo{
    /*height: 120px;*/
    width: 100%;
    margin: 35px 0px;
}
.extra-container{
   
    width: 100%;
    background-image: linear-gradient(90deg,#333 5%,#181818 95%);
    padding: 0 64px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.extra-container button{
    margin-top: 10px;
}
.movie-single-signin-btn{
    position: absolute;
    top:18px;
}
@media only screen and (max-width: 600px){
    .movie-primary-detail-container {
        text-align: left;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100vh;
        background: linear-gradient(88deg, black, transparent);
    }
    
}