.grid_container{
    width: 100%;
    height: 100%;
    display: initial;
    margin: 0;
}
/* img{
    width: 300px;
    padding: 10px 10px;
    display: flex;
} */
/* img:hover{
    width: 350px;
} */
.item{
    width: 373px;
    max-width: 100%;
    display: inline-block;
}
.main-slidersec{
    text-align: center;
}